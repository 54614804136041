import { sshGatewayUrl } from "./constants";

export const queryUserName = (authToken: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${authToken}`);
  myHeaders.append("Content-Type", "application/json");

  const graphql = JSON.stringify({
    query: `
      query whiteboardUserName {
        myTenantUser {
          name
        }
      }
    `,
    variables: {},
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: graphql,
    redirect: "follow",
  };

  return fetch(sshGatewayUrl, requestOptions as RequestInit)
    .then((response) => response.json())
    .then((result) => {
      const username = result?.data?.myTenantUser?.name;
      return username ?? Promise.reject(new Error("Could not get username"));
    });
};
