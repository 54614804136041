import { createContext, useContext } from "react";

interface SymContext {
  username: string | null;
}

const symContext = createContext<SymContext>({ username: null });
export const SymContextProvider = symContext.Provider;
export const useSymContext = () => useContext(symContext);
export const useSymUsername = () => useSymContext().username;
