export const sshPortalOrigins = (
  ((window as any).ENVIRONMENT?.VITE_APP_SSH_PORTAL_ORIGINS as string) ??
  (import.meta.env.VITE_APP_SSH_PORTAL_ORIGINS as string) ??
  ""
)
  .split(",")
  .filter((origin) => !!origin);

export const hasSSHOrigin = sshPortalOrigins.length > 0;

export const isSSHPortalOrigin = (originToCheck: string) =>
  sshPortalOrigins.includes(originToCheck);

export const sshGatewayUrl =
  ((window as any).ENVIRONMENT?.VITE_APP_SSH_GATEWAY_URL as string) ??
  (import.meta.env.VITE_APP_SSH_GATEWAY_URL as string);
